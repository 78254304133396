<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col text-center" style="background-color: #eedc00">
        <img
          alt="Poste Italiane"
          style="padding-top: 17px; padding-bottom: 17px"
          src="https://www.poste.it/img/1473803290446/logo-poste-italiane-small.png"
          srcset="
            https://www.poste.it/img/1473803290446/2X/logo-poste-italiane-small.png 2x
          "
        />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <custom-softphone
          :ccpUrl="ccpUrl"
          :loginUrl="loginUrl"
          :expireStateToAvailable="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomSoftphone from './CustomSoftphone.vue';
export default {
  name: "Home",
  components: {
    CustomSoftphone,
  },
  data() {
    return {
       //ccpUrl: "https://posteccstlcprodsso01.my.connect.aws/ccp-v2/softphone",
       //loginUrl: "https://posteitaliane.awsapps.com/start/#/saml/default/STLC%20Amazon%20Connect%20Prod%20Agent/ins-d777b7fc5bc2d6ce",
      ccpUrl: "https://posteccstlccertsso01.my.connect.aws/connect/ccp-v2",
      loginUrl: "https://posteitaliane.awsapps.com/start/#/saml/default/Amazon%20Connect%20STLC%20Cert/ins-7c91237dca867512",
    };
  },
};
</script>